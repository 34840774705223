import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/logos/m2-logo-white.svg';
import './Footer.scss';

const Footer = (): ReactElement => {
    return (
        <Box component="footer" sx={{ backgroundColor: 'secondary.main', color: 'common.white', paddingY: '25px' }}>
            <p>Powered by</p>
            <a href="https://www.miletwo.us/" target="_blank" rel="noreferrer">
                <img src={Logo} alt="MileTwo Logo" />
            </a>
        </Box>
    );
};

export default Footer;
