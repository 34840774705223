import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1a3a44',
        },
        secondary: {
            main: '#016848',
        },
        error: {
            main: '#ed0000',
            contrastText: '#fff7f7',
        },
        success: {
            main: '#228646',
        },
        warning: {
            main: '#eaa118',
        },
    },
    typography: {
        fontFamily: '"Montserrat", sans-serif, "san-serif"',
        htmlFontSize: 16, // base font size for rem calcs is 16px
        h3: {
            fontSize: '1.125rem',
            fontWeight: 700,
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 700,
            opacity: '0.6',
        },
        h5: {
            fontSize: '0.8rem',
            fontWeight: 700,
        },
        button: {
            textTransform: 'capitalize',
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    border: '2px solid',
                    '&:hover': {
                        border: '2px solid',
                    },
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: 'elevation',
                elevation: 3,
            },
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    marginTop: '3rem',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                color: 'secondary',
                size: 'small',
            },
        },
    },
});

export default theme;
