import React, { ReactElement } from 'react';
import { Box, Button } from '@mui/material';
import { useWindowSize, TABLET_WIDTH } from 'techsuite-trl-mrl-calc';
import Logo from '../../assets/logos/techgauge-logo.svg';
import M2Logo from '../../assets/logos/mile-two-logo.png';
import FeedbackIcon from '../../assets/logos/review-icon.svg';
import './Header.scss';

const Header = (): ReactElement => {
    const [width] = useWindowSize();
    const mobile = width < TABLET_WIDTH;

    return (
        <Box sx={{ backgroundColor: 'secondary.main', color: 'common.white' }} className="AppHeader">
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <img src={M2Logo} alt="Mile Two logo" />
                <img src={Logo} alt="Techgauge Logo" />
            </Box>
            {mobile ? (
                <Button
                    color="secondary"
                    variant="contained"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdvSCF441tj60zvD2iM9c2T99SfxMVQYJoMaHZgjw-VyiILsw/viewform"
                    disableElevation
                    target="_blank"
                >
                    <Box
                        component="img"
                        src={FeedbackIcon}
                        sx={{ width: '100%', color: 'common.white' }}
                        alt="Provide Feedback"
                    />
                </Button>
            ) : (
                <Box sx={{ marginRight: '2rem', display: 'flex', gap: '1rem', textAlign: 'center' }}>
                    <Button
                        sx={{ color: 'common.white' }}
                        variant="text"
                        href="https://www.miletwo.us/connect-with-us/"
                        target="_blank"
                    >
                        Contact Us
                    </Button>
                    <Button
                        sx={{ backgroundColor: 'common.white', color: 'secondary.main' }}
                        color="secondary"
                        variant="contained"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdvSCF441tj60zvD2iM9c2T99SfxMVQYJoMaHZgjw-VyiILsw/viewform"
                        disableElevation
                        target="_blank"
                    >
                        Provide Feedback
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default Header;
