import React, { ReactElement } from 'react';
import { TrlMrlCalculator } from 'techsuite-trl-mrl-calc';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/header/Header';
import theme from './assets/muiTheme';
import Footer from './components/footer/Footer';
import './App.scss';

export default (): ReactElement => {
    const valueReturned = (value: Record<string, any>): any => {
        if (value.dateCompleted && process.env.NODE_ENV === 'production') {
            window.gtag('event', 'trlmrl_assessment_completed', {
                trl_mrl_assessment_completed: 'true',
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <section className="Main">
                <TrlMrlCalculator
                    displayRestart
                    returnData={valueReturned}
                    displayInstructions
                    displayPdf
                    theme={theme}
                />
            </section>
            <Footer />
        </ThemeProvider>
    );
};
